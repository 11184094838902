import React from "react"

import { PhotoPlaceholder } from "react-placeholder-image"
import { GiStoneThrone } from "react-icons/gi"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const FruitsmithPage = () => (
  <Layout>
    <SEO title="Home" />

    <div className="grid-2-2 has-mb-5">
      <div
        data-sal="slide-up"
        data-sal-delay="500"
        data-sal-easing="ease"
        data-sal-duration="900"
        style={{ minHeight: "50vh", justifyContent: "space-between" }}
        className="has-padding-4  has-bg-blue align-content-vcenter has-txt-white"
      >
        <div>
          <h1 className="has-txt-bold" style={{ marginBottom: "1rem" }}>
            Fruitsmith
          </h1>
          <h3>
            <span className="has-mb-5 tags has-txt-bold">Branding</span>{" "}
            <span className="has-mb-5 tags has-txt-bold">Strategy</span>{" "}
            <span className="has-mb-5 tags has-txt-bold">
              Web Design & development
            </span>{" "}
          </h3>
        </div>
        <p></p>
      </div>

      <div
        data-sal="slide-up"
        data-sal-delay="300"
        data-sal-easing="ease"
        data-sal-duration="900"
        style={{ minHeight: "50vh" }}
      >
        <img src={"/images/fsmith/1.jpg"} alt="" />
      </div>
      <div
        data-sal="slide-up"
        data-sal-delay="400"
        data-sal-easing="ease"
        data-sal-duration="900"
        style={{ minHeight: "50vh" }}
      >
        <img src={"/images/fsmith/2.jpg"} alt="" />
      </div>
      <div
        data-sal="slide-up"
        data-sal-delay="600"
        data-sal-easing="ease"
        data-sal-duration="900"
        style={{ minHeight: "50vh" }}
      >
        <img src={"/images/fsmith/3.jpg"} alt="" />
      </div>
    </div>

    <div className="grid-1-2 has-mb-5">
      <div
        data-sal="slide-up"
        data-sal-delay="300"
        data-sal-easing="ease"
        data-sal-duration="900"
        style={{ minHeight: "50vh", cursor: "pointer" }}
      >
        <img src={"/images/fsmith/4.jpg"} alt="" />
      </div>
      <div
        data-sal="slide-up"
        data-sal-delay="400"
        data-sal-easing="ease"
        data-sal-duration="900"
        style={{ minHeight: "50vh", cursor: "pointer" }}
      >
        <img src={"/images/fsmith/5.jpg"} alt="" />
      </div>
    </div>
    <div className="grid-1-2 has-mb-5">
      <div
        data-sal="slide-up"
        data-sal-delay="300"
        data-sal-easing="ease"
        data-sal-duration="900"
        style={{ minHeight: "50vh", cursor: "pointer" }}
      >
        <img src={"/images/fsmith/6.jpg"} alt="" />
      </div>
      <div
        data-sal="slide-up"
        data-sal-delay="400"
        data-sal-easing="ease"
        data-sal-duration="900"
        style={{ minHeight: "50vh", cursor: "pointer" }}
      >
        <img src={"/images/fsmith/7.jpg"} alt="" />
      </div>
    </div>
  </Layout>
)

export default FruitsmithPage
